export const getFacetsFilterWithoutDestination = ({ slug, $strapiApi }) => {
  return $strapiApi
    .getFacetsFilterByUrl(slug)
    .then((res) => {
      const { data } = res

      return {
        facets: data.facets,
        homeType: data.home_type,
        indexation: data.indexation,
        metaDescription: data.meta_description,
        metaTitle: data.meta_title,
        title: data.title,
        urlEn: data.url_en,
        urlFr: data.url_fr,
      }
    })
    .catch(() => {
      return null
    })
}

export const getFacetsFilterWithDestination = ({
  destination_slug,
  slug,
  $strapiApi,
}) => {
  return $strapiApi
    .getFacetsFilterByDestinationUrl(destination_slug, slug)
    .then((res) => {
      const { data } = res

      return {
        destinationId: data.destination_id,
        facets: data.facets,
        homeType: data.home_type,
        indexation: data.indexation,
        metaDescription: data.meta_description,
        metaTitle: data.meta_title,
        title: data.title,
        urlEn: data.url_en,
        urlFr: data.url_fr,
      }
    })
    .catch(() => {
      return null
    })
}

export const getFacetsFilterByDestination = ({ destinationId, $strapiApi }) => {
  return $strapiApi
    .getFacetsFilterByDestinationId(destinationId)
    .then((res) => {
      return res.data || []
    })
    .catch(() => {
      return []
    })
}
